.section-options {
	margin-left: auto;
}

.mobile-layout-card {
	display: flex !important;
	flex-wrap: wrap;
}

.section-title {
	font-family: $helvetica;
	font-weight: 500;
	@include media-breakpoint-up(md) {
		font-size: 24px;
	}
	@include media-breakpoint-up(lg) {
		font-size: 2rem;
	}

	@include media-breakpoint-up(xl) {
		font-size: 2.25rem;
	}
}

.library-top-slider-title {
	font-family: $helvetica;
	font-size: 1.5rem;

	@media only screen and (max-width: 900px) {
		margin-bottom: 23px;
	}

	@include media-breakpoint-up(lg) {
		font-size: 2.375rem;
	}
}

.mobile-selection {
	display: flex;
	justify-content: space-between;
	width: 90%;
	margin: 0 auto;
	margin-bottom: 11px;
	.section-title {
		font-size: 14px;
		margin-bottom: 0;
	}
}

.select-options {
	display: flex;
	align-items: center;
	justify-content: center;
}

.library-select {
	background: #f6c944;
	&.mobile-select-color {
		background-color: #50c09a;
	}
	border: none;
	padding: 10px 67px 10px 47px;
	color: white;
	font-size: 1.5rem;
	margin-left: 52px;
	border-radius: 48px;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	background-image: url('../images/down-arrow.svg');
	background-repeat: no-repeat;
	background-position: calc(100% - 30px) center;
	@include media-breakpoint-down(md) {
		font-size: 14px;
		padding: 4px 22px;
		border-radius: 12px;
		margin-right: 8px;
		background-position: calc(100% - 5px) center;
		background-size: 17px;
		margin-left: 0;

		&:last-child {
			margin-right: 0;
		}
	}

	@include media-breakpoint-up(md) {
		font-size: 17px;
		padding: 4px 25px;
		border-radius: 12px;
		margin-right: 8px;
		background-position: calc(100% - 5px) center;
		background-size: 17px;
		margin-left: 0;

		&:last-child {
			margin-right: 0;
		}
	}

	@include media-breakpoint-up(lg) {
		font-size: 20px;
		padding: 7px 29px;
		border-radius: 12px;
		margin-right: 8px;
		background-position: calc(100% - 5px) center;
		background-size: 17px;
		margin-left: 0;

		&:last-child {
			margin-right: 0;
		}
	}

	@include media-breakpoint-up(xl) {
		padding: 10px 67px 10px 47px;
		color: white;
		border-radius: 48px;
		font-size: 1.5rem;
		margin-left: 52px;
		margin-right: 8px;
		background-position: calc(100% - 17px) center;
		background-size: 30px;

		&:last-child {
			margin-right: 0;
		}
	}
}

.view-more-lb {
	text-align: center;
	text-decoration: none;
	color: #ee7a5c;
	font-size: 20px;
	font-family: 'Hind Madurai', sans-serif;
	font-weight: 500;
	display: block;
	margin-bottom: 20px;
	&:hover {
		color: #ee7a5c;
	}
}

.library-section-container {
	@include media-breakpoint-up(md) {
		padding-bottom: 40px !important;
	}
	@include media-breakpoint-up(lg) {
		background: url('../images/libreary-bg-eclipse.svg') no-repeat right top;
	}
}

.library-content {
	margin-top: 54px !important;
}

.section-wrapper-blog {
	@media only screen and (max-width: 900px) {
		padding-bottom: 50px !important;
		margin-top: 49px;
	}
}

.contact-content {
	// padding-right: 0px !important;

	// @include media-breakpoint-up(lg) {
	// 	padding-right: auto !important;
	// }
}
