$deep-orange: #ee7a5c;
$primary-color: red;
$lignt-orange: #eb996e;
// FONTS
$helvetica: 'HelveticaNeue', sans-serif;
$madurai: 'Hind Madurai', sans-serif;

// BOOTSTRAP CUSTOMIZATION
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px,
	xxxl: 1600px,
	xxxxl: 1925px,
	ultraxl: 2190px,
);

$container-max-widths: (
	lg: 960px,
	xl: 1140px,
	xxl: 1320px,
	xxxl: 1400px,
	xxxxl: 1520px,
	ultraxl: 1560px,
);
