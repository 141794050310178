#pricing-page > h3 {
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: center;
}

#pricing-page > .price-item {
    display: inline-flex;
    flex-direction: column;
    width: calc((100%/3) - (50px));
    background-color: rgba(245,245,245,255);
    padding: 25px;
    border: 25px;
    margin: 25px;
    border-radius: 25px;
    min-height: 440px;
    margin-bottom: 40px;
}

#pricing-page > div > p.light {
    font-weight: 100;
}

#pricing-page > div > p {
    color: rgba(115,115,115,255);
}




#pricing-page > div > button {
    width: 100%;
    font-size: 25px;
    border-radius: 20px;
    background-color: rgba(238,122,92,255);
    color: white;
}

#pricing-page > div > ul > li {
    color: rgba(128,128,128,255);
}

#pricing-page > div > ul > li > span {
    position: relative;
    left: 10px;
}

#pricing-page > div > ul {
    list-style: none;
    padding-left: 0;
}

#pricing-page > div > ul > li:before {
    content: '✓';
}



@media only screen and (max-width: 1000px) {
    #pricing-page > .price-item {
        width: calc(100% - (50px));
        padding: 25px;
        border: 25px;
    }
  }