.h1 {
	font-size: 2.1rem;
	font-weight: 700;
	font-family: 'HelveticaNeue', sans-serif;
}

.h2 {
	font-size: 1.7rem;
	font-weight: 700;
	font-family: 'HelveticaNeue', sans-serif;
}

.h3 {
	font-size: 1.3rem;
	font-weight: 600;
	font-family: 'HelveticaNeue', sans-serif;
}

.h4 {
	font-size: 1.1rem;
	font-weight: 600;
	font-family: 'HelveticaNeue', sans-serif;
}

.bold {
	font-weight: bold;
}

.mt {
	margin-top: 1rem;
}

.h1mt {
	margin-top: 2rem;
}

.p,
.li {
	font-size: 1rem;
	font-family: 'HelveticaNeue', sans-serif;
	margin-bottom: 1rem;
	font-weight: 400;
}

.ul,
.ol {
	margin-top: 1rem;
}
