#blogRead {
    display: table;
    max-width: 800px;
    margin: 25px;
    margin: auto;
}

#blogRead > h3 {
    margin-top: 50px;
}

#blogRead > img {
    width: 800px;
}

#blogRead > .author {
    margin: 10px;
    margin-top: 0px;
    width: 50px;
}

#blogRead > .info {
    display: inline-flex;
    position: relative;
    bottom: 16px;
}

#blogRead > .duration {
    display: inline-flex;
    margin-left: 50px;
}

#blogRead > .duration > img {
    width: 16px;
    margin-right: 10px;
}

#blogRead > .shareIcon {
    float: right;
    margin-right: 10px;
    width: 20px;
}
