
.dropdown-wrapper {
    position: relative;
}

.dropdown {
	width: 300px;
	border-radius: 10px;
	box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
	background-color: white;
}

.dropdown-header {
	display: flex !important;
	justify-content: space-between;
	align-items: center;
	padding: 15px;
	cursor: pointer;
}

.dropdown-body {
	padding: 5px;
	border-top: 1px solid #e5e8ec;
	display: none;

    &.open {
        display: block;
        position: absolute;
        width: 100%;
        z-index: 10;
        background: #fff;
        border-radius: 5px;
    }
}

.dropdown-item {
	padding: 10px;
}

.dropdown-item:hover {
	cursor: pointer;
}

.dropdown-item-dot {
	opacity: 0;
	color: #91a5be;
	transition: all 0.2s ease-in-out;
}

.dropdown-item-dot.selected {
	opacity: 1;
}