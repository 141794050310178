#BlogConsentPage > img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}

#BlogConsentPage {
    max-width: 1200px;
}

@media only screen and (min-width: 1200px) {
    #BlogConsentPage {
        max-width: 1200px;
        margin-left: calc((100vw - 1200px)/2);
        margin-right: calc((100vw - 1200px)/2);
    }
  }